



















































































































































































































































































































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import Closeout from '@/models/Closeout'
import Widget from '@/components/Widget/Widget.vue'
import Api from '@/models/Api'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import moment from 'moment'
import ViewModel from '@/models/ViewModel'
import WebMessage from '@/models/WebMessage'
import { FormWizard, TabContent } from 'vue-form-wizard'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import Inventory from '@/models/Inventory'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'

// @ts-ignore
@Component({
  // @ts-ignore
  components: {
    Widget,
    SelectPicker,
    FormWizard,
    TabContent,
    IconAction,
    FormInput,
    SearchInput,
    CompanyPicker,
  },
})
export default class CloseoutEdit extends ViewModel {
  @Prop({ default: null })
  public id!: string | null;

  public query: string[] = [];

  public select_all: boolean = false;

  public loading: boolean = false;

  public title: string = 'Create Closeout';

  public closeouts: Closeout[] = [];

  public step: number = 1;

  public busy = true;

  public custom_closeout = {
    year: parseInt(moment().format('YYYY')),
    month: parseInt(moment().format('M')),
    publisher_id: '',
    partner_id: '',
  };

  public modal: any = {
    custom: false,
  };

  public get publisher_options() {
    return Inventory.module.publisher_options
  }

  public get selected_closeouts(): Closeout[] {
    return this.closeouts.filter((c: Closeout) => c.selected)
  }

  public get publishers() {
    return this.selected_closeouts
      .map((item: Closeout) => ({ name: item.publisher, id: item.publisher }))
      .filter((value, index, self) => self.indexOf(value) === index)
  }

  public get advertisers() {
    return this.selected_closeouts
      .map((item: Closeout) => ({ name: item.partner_name, id: item.partner_id }))
      .filter((value, index, self) => self.indexOf(value) === index)
  }

  public getRevenues(partner_id: string | null = null) {
    let data = this.selected_closeouts

    if (partner_id != null) {
      data = data.filter(
        (closeout: Closeout) => closeout.partner_id == partner_id,
      )
    }
    return data.reduce((carry, current) => current.dynamicRevenues + carry, 0)
  }

  public getCost(publisher_id: string | null = null) {
    let data = this.selected_closeouts

    if (publisher_id != null) {
      data = data.filter(
        (closeout: Closeout) => closeout.publisher == publisher_id,
      )
    }
    return data.reduce((carry, current) => current.dynamicCost + carry, 0)
  }

  public options!: any;

  public fields_line_items: any = [
    {
      key: 'check',
    },
    {
      key: 'date',
      label: 'Date',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'publisher',
      label: 'Publisher',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'partner_name',
      label: 'Partner',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'impressions',
      label: 'Impressions',
      sortable: true,
      class: 'text-center',
    },
  ];

  public fields_rate: any = [
    {
      key: 'date',
      label: 'Date',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'publisher',
      label: 'Publisher',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'partner_name',
      label: 'Partner',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'rate_publisher',
      label: 'Rate Publisher',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'rate_advertiser',
      label: 'Rate Advertiser',
      sortable: true,
      class: 'text-center',
    },
  ];

  public fields_impressions: any = [
    {
      key: 'date',
      label: 'Date',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'publisher',
      label: 'Publisher',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'partner_name',
      label: 'Partner',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'impressions',
      label: 'Impressions',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'impressions_publisher',
      label: 'Publisher Impressions',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'publisher_discrepancy',
      label: 'Publisher Discrepancy',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'impressions_advertiser',
      label: 'Advertiser Impressions',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'advertiser_discrepancy',
      label: 'Advertiser Discrepancy',
      sortable: true,
      class: 'text-center',
    },
  ];

  public month_options: object[] = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'Semptember', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 },
  ];

  public _year_options: object[] = [];

  public get year_options(): object[] {
    if (!this._year_options) {
      this._year_options = []
      let current = parseInt(moment().format('YYYY'))
      while (current >= 2017) {
        this._year_options.push({ name: current, value: current })
        current--
      }
    }
    return this._year_options
  }

  public get select_all_indeterminate() {
    return (
      this.closeouts.some((c: Closeout) => c.selected)
      && this.closeouts.some((c: Closeout) => !c.selected)
    )
  }

  @Watch('select_all')
  public onSelectAll() {
    this.closeouts.forEach((c: Closeout) => {
      c.selected = this.select_all
    })
  }

  public rowClickHandler(item: Closeout) {
    this.closeouts.forEach((c: Closeout) => {
      if (c.key == item.key) c.selected = !c.selected
    })
  }

  public openCustomCloseoutModal() {
    this.modal.custom = true
  }

  public handleCustomCloseoutOk() {
    // @ts-ignore
    this.$refs['custom-form'].validate().then((success: boolean) => {
      if (!success) {
        return
      }
      this.modal.custom = false
      this.addCustomCloseout()
    })
  }

  public addCustomCloseout() {
    const closeout = new Closeout()
    closeout.custom = true
    closeout.year = this.custom_closeout.year
    closeout.month = this.custom_closeout.month
    closeout.publisher = this.custom_closeout.publisher_id
    closeout.partner_id = this.custom_closeout.partner_id
    closeout.selected = true
    this.closeouts.push(closeout)
  }

  public onSubmit() {
    const has_selected = this.closeouts.some((c: Closeout) => c.selected)

    if (!has_selected) {
      WebMessage.error('Please select at least 1 item to submit the form.')
      return
    }

    if (this.id) {
      this.closeouts[0].save().then(this.onSave).catch(this.onError)
    } else {
      const api = new Api()
      const data = this.selected_closeouts
      api
        .post('closeout', { closeouts: data })
        .then(this.onSave)
        .catch(this.onError)
    }
  }

  public cancel() {
    this.$router.push({ name: 'Closeouts' })
  }

  public onSave(response: any) {
    Closeout.toObjectList(response.data.result.closeouts)

    this.loading = false
    this.$router.push({ name: 'Closeouts' })
    WebMessage.success('Closeout saved!')
  }

  public onError() {
    this.loading = false
  }

  public created() {
    if (this.id) {
      this.title = 'Edit Closeout'
      Closeout.get(this.id).then(c => {
        if (c instanceof Closeout) {
          this.closeouts = [c]
          this.closeouts[0].selected = true
        }
        this.busy = false
      })
    } else {
      Closeout.getOpen().then(closeouts => {
        this.closeouts = closeouts
        this.busy = false
      })
    }
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
      this.query = []
    }
  }

  public validateStep() {
    const has_selected = this.closeouts.some((c: Closeout) => c.selected)

    if (!has_selected) {
      WebMessage.error('Please select at least 1 item.')
      return false
    }
    return true
  }
}
