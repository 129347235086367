import store from '@/store'
import moment from 'moment'
import { getModule } from 'vuex-module-decorators'
import InvoiceModule from '@/store/model/InvoiceModule'
import Company from './Company'
import Closeout from './Closeout'
import Api from './Api'
import WebMessage from './WebMessage'
import PaginateOptions from './interface/PaginateOptions'

export default class Invoice {
    public id: string | null = null;

    public external_id: string | null = null;

    public company_id: string | null = null;

    public partner_id: string | null = null;

    public tax_percentage: number = 0;

    public tax: number = 0;

    public sub_total: number = 0;

    public number: number = 0;

    public total: number = 0;

    public due_at: string = moment().format('YYYY-MM-DD HH:mm:ss');

    public notes: string = '';

    public group_name: string = 'Sports Stream Live';

    public created_at: string = Date();

    public group_items: boolean = false;

    public total_impressions: number = 0;

    public average_rate: number = 0;

    public paid: number = 0;

    public status: number = 0;

    public get expired(): boolean {
      const date = this.due_at.split(/[- :]/)
      // @ts-ignore
      const due = new Date(date[0], date[1] - 1, date[2], date[3], date[4], date[5])
      const now = new Date()
      return due.getTime() < now.getTime()
    }

    public _closeouts: Closeout[] = [];

    public get closeouts() {
      if (this._closeouts.length == 0 && this.id) this._closeouts = Closeout.filter((c: Closeout) => c.invoice_id === this.id)

      return this._closeouts
    }

    private _company: Company | null = null;

    get company(): Company | null {
      if (this.company_id && (this._company == null || this._company.id != this.company_id)) {
        this._company = Company.find(this.company_id)
      }

      return this._company
    }

    private _partner: Company | null = null;

    get partner(): Company | null {
      if (this.partner_id && (this._partner == null || this._partner.id != this.partner_id)) {
        this._partner = Company.find(this.partner_id)
      }

      return this._partner
    }

    public updateTotals() {
      if (this.closeouts.length > 0) {
        this.sub_total = this.closeouts.reduce((carry, item) => carry + item.revenues, 0)
        this.tax = this.sub_total * (this.tax_percentage / 100)
        this.total = this.sub_total + this.tax
        if (!this.id) {
          const max = Invoice.module.data.reduce((carry: number, item: any) => (item.number > carry ? item.number : carry), 0)
          this.number = max + 1
        }
        this.total_impressions = this.closeouts.reduce((carry: number, item: any) => carry + item.impressions_advertiser, 0)
        this.average_rate = this.sub_total / this.total_impressions * 1000
      }
    }

    public save() {
      const api = new Api()

      const data = {
        partner_id: this.partner_id,
        tax_percentage: this.tax_percentage,
        closeouts: this._closeouts.map((c: Closeout) => c.id),
        group_items: this.group_items,
        group_name: this.group_name,
        due_at: this.due_at,
        notes: this.notes,
      }

      if (this.id) {
        return api.put(`invoice/${this.id}`, data).then(this.onSave)
      }
      return api.post('invoice', data).then(this.onSave)
    }

    public send() {
      const api = new Api()
      return api.put(`invoice/${this.id}/send`).then(this.onSend)
    }

    public delete() {
      const api = new Api()

      return api.delete(`invoice/${this.id}`, {}).then(this.onDelete)
    }

    private onDelete(response: any) {
      const invoice = Invoice.filter(response.data.result.deleted)
      Closeout.toObjectList(response.data.result.pending)

      Invoice.module.delete(invoice)

      WebMessage.success('Invoice deleted!')

      return response
    }

    private onSave(response: any) {
      Closeout.toObjectList(response.data.result.closeouts)

      const invoice = Invoice.toObject(response.data.result.invoice)

      WebMessage.success(`Invoice #${invoice.number} saved!`)

      return response
    }

    private onSend(response: any) {
      const invoice = Invoice.toObject(response.data.result.invoice)

      WebMessage.success(`Invoice #${invoice.number} sent!`)

      return response
    }

    public static toObject(data: any, cache: boolean = true): Invoice {
      const invoice = new Invoice()

      invoice.id = data.id
      invoice.external_id = data.external_id
      invoice.company_id = data.company_id
      invoice.partner_id = data.partner_id
      invoice.tax_percentage = data.tax_percentage
      invoice.tax = data.tax
      invoice.sub_total = data.sub_total
      invoice.number = data.number
      invoice.total = data.total
      invoice.due_at = data.due_at
      invoice.notes = data.notes
      invoice.group_name = data.group_name
      invoice.created_at = data.created_at
      invoice.group_items = data.group_items
      invoice.total_impressions = data.total_impressions
      invoice.average_rate = data.average_rate
      invoice.paid = data.paid
      invoice.status = data.status

      if (data.closeouts) {
        Closeout.toObjectList(data.closeouts)
      }

      if (data.company) {
        Company.toObject(data.company)
      }

      if (data.partner) {
        Company.toObject(data.partner)
      }

      invoice.updateTotals()

      //  Cache Object
      if (cache) Invoice.module.update(invoice)

      return invoice
    }

    public static toObjectList(data: any, cache: boolean = true): Invoice[] {
      const invoices = new Array<Invoice>()
      data.forEach((value: any) => {
        const invoice = Invoice.toObject(value, false)
        invoices.push(invoice)
      })

      //  Cache Object
      if (cache) Invoice.module.update(invoices)

      return invoices
    }

    // State Management
    public static get module(): InvoiceModule {
      if (!store.hasModule('invoice')) {
        store.registerModule('invoice', InvoiceModule)
      }

      return getModule(InvoiceModule)
    }

    public static find(id: string): Invoice | null {
      const o = Invoice.module.data.find(invoice => invoice.id === id)
      return o instanceof Invoice ? o : null
    }

    public static filter(ids: string[]): Invoice[] {
      return Invoice.module.data.filter(invoice => invoice.id && ids.includes(invoice.id))
    }

    public static async get(id: string): Promise<Invoice | null> {
      return Invoice.module.find(id)
    }

    public static async paginate(options: PaginateOptions) {
      return Invoice.module.paginate(options)
    }
}
