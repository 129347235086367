import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import Closeout from '@/models/Closeout'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'closeout',
})
export default class CloseoutModule extends VuexModule {
    public data: Closeout[] = [];

    public options: SelectOption[] = [];

    public synchronized: boolean = false;

    public get api() {
      return new Api(false)
    }

    @Mutation
    public setSynchronized(status: boolean) {
      this.synchronized = status
    }

    @Mutation
    public update(closeouts: Closeout | Closeout[]) {
      let data: Array<Closeout> = []
      if (closeouts instanceof Closeout) {
        data.push(closeouts)
      } else {
        data = closeouts
      }

      let result = this.data

      data.forEach((closeout: Closeout) => {
        let found: boolean = false
        result = result.map((obj: Closeout) => {
          if (obj.id == closeout.id) {
            found = true
            return closeout
          }
          return obj
        })

        if (!found) {
          result.push(closeout)
        }
      })

      this.data = result
    }

    @Mutation
    public delete(closeouts: Closeout | Closeout[]) {
      let data: Array<Closeout> = []
      if (closeouts instanceof Closeout) {
        data.push(closeouts)
      } else {
        data = closeouts
      }

      let result = this.data

      data.forEach((closeout: Closeout) => {
        result = result.filter((obj: Closeout) => obj.id != closeout.id)
      })

      this.data = result
    }

    @Action
    public async find(id: string): Promise<Closeout | null> {
      return new Promise(resolve => {
        const o = this.data.find(closeout => closeout.id === id)

        if (o instanceof Closeout) {
          resolve(o)
        } else {
          return this.api.get(`closeout/${id}`)
            .then(response => {
              // Parse & cache data
              const data = Closeout.toObject(response.data.result.closeout)

              if (data instanceof Closeout) {
                resolve(data)
              }
            }).catch(() => {
              resolve(null)
            })
        }
      })
    }

    @Action
    public async getOpen(): Promise<Closeout[]> {
      return this.api.get('closeouts/open')
        .then(response => {
          // Parse & cache data
          const data = Closeout.toObjectList(response.data.result.closeouts, false)

          return data
        }).catch(() => [])
    }

    @Action
    public async getPending(): Promise<Closeout[]> {
      return this.api.get('closeouts/pending')
        .then(response => {
          // Parse & cache data
          const data = Closeout.toObjectList(response.data.result.closeouts, false)

          return data
        }).catch(() => [])
    }

    @Action
    public async paginate(options: PaginateOptions) {
      return this.api.get('closeouts/paginate', options)
        .then(response => {
          // Parse & cache data
          const data = Closeout.toObjectList(response.data.result.closeouts)

          return {
            records: response.data.result.records,
            data,
          }
        }).catch(() => ({
          records: 0,
          data: [],
        }))
    }
}
