





























import { Component, Vue, Prop } from 'vue-property-decorator'
import Invoice from '@/models/Invoice'

@Component({
  components: {},
})
export default class InvoiceBadgeStatus extends Vue {
  @Prop()
  public invoice!: Invoice;
}
