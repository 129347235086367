



































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import Company from '@/models/Company'
import Profile from '@/models/Profile'
import User from '@/models/User'
import SelectOption from '@/models/interface/SelectOption'
import MediaPlan from '@/models/MediaPlan'
import IconAction from '@/components/IconAction/IconAction.vue'

@Component({
  components: {
    SelectPicker,
    IconAction,
  },
})
export default class SearchInput extends Vue {
  private debounce: number = 500

  private timeout: any = null

  @Prop({ default: () => {} })
  public extras!: any

  @Prop({ default: 'tag' })
  public mode!: string

  @Prop({ default: false })
  public show_helper!: boolean

  @Prop({ default: [] })
  public value!: string[]

  @Prop({
    default: () => ({
      company_fields: [],
      custom_fields: [],
    }),
  })
  public settings!: any

  public get local_show_helper(): boolean {
    return this.show_helper
  }

  public set local_show_helper(value: boolean) {
    this.$emit('update:show_helper', value)
  }

  public local_value: string[] = []

  public busy: boolean = false

  public update: boolean = true

  public custom_options: object[] = []

  public local_options: object[] = []

  public get search_options() {
    return [
      ...this.custom_options,
      ...this.local_options,
      ...this.company_options,
      ...this.user_options,
      ...this.profile_options,
      ...this.media_plan_options,
    ]
  }

  public get dynamicFields() {
    let ret :any = []

    if (this.settings.company_fields) ret = [...ret, ...this.settings.company_fields]

    if (this.settings.user_fields) ret = [...ret, ...this.settings.user_fields]

    if (this.settings.media_plan_fields) ret = [...ret, ...this.settings.media_plan_fields]

    return ret
  }

  public resetFields() {
    this.custom_options = []
    this.local_options = []
    this.company_options = []
    this.user_options = []
    this.profile_options = []
    this.media_plan_options = []
  }

  public addCustomTag(tag: any) {
    this.custom_options.push(tag)
  }

  public addTag(newTag: string) {
    this.addCustomTag({
      name: newTag,
      value: newTag,
    })
    this.local_value.push(newTag)
  }

  @Watch('local_value')
  public onChangeValue() {
    this.$emit('input', this.local_value)
    this.update = false
  }

  @Watch('value')
  public onChangeProp() {
    if (this.update) {
      this.local_value = this.value
    }
    this.update = true
  }

  public has(tag: SelectOption) {
    return this.local_value.some(o => o === tag.value)
  }

  public remove(tag: SelectOption) {
    this.local_value = this.local_value.filter(o => o !== tag.value)
  }

  public add(tag: any) {
    const value = this.local_value
    value.push(tag.value)
    this.local_value = value
  }

  public searchOptions(search = '*') {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      let type = null

      if (
        this.settings.company_fields
        && this.settings.company_fields.some((o: any) => search.includes(`${o.name}:`))
      ) {
        type = 'company'
      } else if (
        this.settings.user_fields
        && this.settings.user_fields.some((o: any) => search.includes(`${o.name}:`))
      ) {
        type = 'user'
      } else if (
        this.settings.profile_fields
        && this.settings.profile_fields.some((o: any) => search.includes(`${o.name}:`))
      ) {
        type = 'profile'
      } else if (
        this.settings.media_plan_fields
        && this.settings.media_plan_fields.some((o: any) => search.includes(`${o.name}:`))
      ) {
        type = 'media_plan'
      }

      if (!type || type == 'company') this.searchCompanyOptions(search)
      if (!type || type == 'user') this.searchUserOptions(search)
      if (!type || type == 'profile') this.searchProfileOptions(search)
      if (!type || type == 'media_plan') this.searchMediaPlanOptions(search)
    }, this.debounce)
  }

  public company_options: SelectOption[] = []

  public searchCompanyOptions(search = '*') {
    if (!this.settings.company_fields) return

    this.busy = true

    this.settings.company_fields.forEach((field: any) => {
      search = search.replace(`${field.name}:`, '')
    })

    if (!search) search = '*'

    Company.module
      .searchOptions({
        search: search.includes('*') ? search : `*${search}*`,
      })
      .then(response => {
        const options: SelectOption[] = []
        response.forEach(o => {
          this.settings.company_fields.forEach((field: any) => {
            if (!field.type || field.type == o.type) {
              options.push(new SelectOption(`${field.name}:${o.name}`, `${field.key}:${o.value}`))
            }
          })
        })

        this.company_options = options
        this.busy = false
      })
  }

  public media_plan_options: SelectOption[] = []

  public searchMediaPlanOptions(search = '*') {
    if (!this.settings.user_fields) return

    this.busy = true

    this.settings.user_fields.forEach((field: any) => {
      search = search.replace(`${field.name}:`, '')
    })

    if (!search) search = '*'

    MediaPlan.searchOptions({
      search: search.includes('*') ? search : `*${search}*`,
    }).then(response => {
      const options: SelectOption[] = []
      response.forEach(o => {
        this.settings.media_plan_fields.forEach((field: any) => {
          if (!field.type || field.type == o.type) {
            options.push(
              new SelectOption(`${field.name}:${o.full_name}`, `${field.key}:${o.value}`),
            )
          }
        })
      })

      this.media_plan_options = options
      this.busy = false
    })
  }

  public user_options: SelectOption[] = []

  public searchUserOptions(search = '*') {
    if (!this.settings.user_fields) return

    this.busy = true

    this.settings.user_fields.forEach((field: any) => {
      search = search.replace(`${field.name}:`, '')
    })

    if (!search) search = '*'

    User.module
      .searchOptions({
        search: search.includes('*') ? search : `*${search}*`,
      })
      .then(response => {
        const options: SelectOption[] = []
        response.forEach(o => {
          this.settings.user_fields.forEach((field: any) => {
            if (!field.type || field.type == o.type) {
              options.push(new SelectOption(`${field.name}:${o.name}`, `${field.key}:${o.value}`))
            }
          })
        })

        this.user_options = options
        this.busy = false
      })
  }

  public profile_options: SelectOption[] = []

  public searchProfileOptions(search = '*') {
    if (!this.settings.profile_fields) return

    this.busy = true

    this.settings.profile_fields.forEach((field: any) => {
      search = search.replace(`${field.name}:`, '')
    })

    if (!search) search = '*'

    Profile.module
      .searchOptions({
        search: search.includes('*') ? search : `*${search}*`,
      })
      .then(response => {
        const options: SelectOption[] = []
        response.forEach(o => {
          this.settings.profile_fields.forEach((field: any) => {
            if (!field.type || field.type == o.type) {
              options.push(new SelectOption(`${field.name}:${o.name}`, `${field.key}:${o.value}`))
            }
          })
        })

        this.profile_options = options
        this.busy = false
      })
  }

  public created() {
    if (this.mode != 'tag') {
      this.local_value = []
      return
    }
    if (this.settings.custom_fields) {
      this.local_options = [...this.local_options, ...this.settings.custom_fields]
    }
    this.local_value = this.value
  }

  public open() {
    this.searchOptions()
  }
}
