/** @format */

import moment from 'moment'

export default class DatePickerDate {
  public start: string = moment().format('YYYY-MM-DD HH:mm:ss')

  public end: string = moment()
    .endOf('month')
    .format('YYYY-MM-DD HH:mm:ss')

  public shortcut: string | number | null = 'month'

  constructor(
    start: string | null = null,
    end: string | null = null,
    shortcut: string | number | null = null,
    format: string | null = null,
  ) {
    if (start != null) {
      this.start = format == null
        ? moment(start, moment.ISO_8601).format('YYYY-MM-DD HH:mm:ss')
        : moment(start, format).format('YYYY-MM-DD HH:mm:ss')
    }
    if (end != null) {
      this.end = format == null
        ? moment(start, moment.ISO_8601).format('YYYY-MM-DD HH:mm:ss')
        : moment(end, format).format('YYYY-MM-DD HH:mm:ss')
    }
    this.shortcut = shortcut
    if (start == null && end == null && shortcut != null) {
      this.buildDateFromShortcut()
    }
  }

  public get start_human() {
    if (typeof this.start === 'string') {
      return moment(this.start, 'YYYY-MM-DD HH:mm:ss').format('MM-DD-YYYY')
    }
    return null
  }

  public get end_human() {
    if (typeof this.end === 'string') {
      return moment(this.end, 'YYYY-MM-DD HH:mm:ss').format('MM-DD-YYYY')
    }
    return null
  }

  public get start_date() {
    return moment(this.start, 'YYYY-MM-DD HH:mm:ss').toDate()
  }

  public get end_date() {
    return moment(this.end, 'YYYY-MM-DD HH:mm:ss').toDate()
  }

  public get start_time() {
    return moment(this.start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
  }

  public get end_time() {
    return moment(this.end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
  }

  public get start_date_string() {
    return moment(this.start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
  }

  public get end_date_string() {
    return moment(this.end, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
  }

  private buildDateFromShortcut() {
    let n = Number(this.shortcut)

    if (!isNaN(n)) {
      this.start = moment()
        .add(n, 'days')
        .format('YYYY-MM-DD')
      this.end = moment().format('YYYY-MM-DD')
    } else {
      switch (this.shortcut) {
        case 'day':
        case 'today':
          this.start = moment()
            .startOf('day')
            .format('YYYY-MM-DD')
          this.end = moment()
            .endOf('day')
            .format('YYYY-MM-DD')
          break
        case '-day':
          this.start = moment()
            .subtract(1, 'days')
            .startOf('day')
            .format('YYYY-MM-DD')
          this.end = moment()
            .subtract(1, 'days')
            .endOf('day')
            .format('YYYY-MM-DD')
          break
        case 'week':
          this.start = moment()
            .startOf('week')
            .format('YYYY-MM-DD')
          this.end = moment()
            .endOf('week')
            .format('YYYY-MM-DD')
          break
        case '-week':
          this.start = moment()
            .subtract(1, 'weeks')
            .startOf('week')
            .format('YYYY-MM-DD')
          this.end = moment()
            .subtract(1, 'weeks')
            .endOf('week')
            .format('YYYY-MM-DD')
          break
        case 'month':
          this.start = moment()
            .startOf('month')
            .format('YYYY-MM-DD')
          this.end = moment()
            .endOf('month')
            .format('YYYY-MM-DD')
          break
        case '-month':
          this.start = moment()
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD')
          this.end = moment()
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD')
          break
        case 'year':
          this.start = moment()
            .startOf('year')
            .format('YYYY-MM-DD')
          this.end = moment()
            .endOf('year')
            .format('YYYY-MM-DD')
          break
        case '-year':
          this.start = moment()
            .subtract(1, 'years')
            .startOf('year')
            .format('YYYY-MM-DD')
          this.end = moment()
            .subtract(1, 'years')
            .endOf('year')
            .format('YYYY-MM-DD')
          break
      }
    }
  }

  public static toObject(data: any): DatePickerDate {
    let date_picker_date

    if (typeof data === 'string' || typeof data === 'number') {
      date_picker_date = new DatePickerDate(null, null, data)
    } else if (data.shortcut) {
      date_picker_date = new DatePickerDate(null, null, data.shortcut)
    } else {
      date_picker_date = new DatePickerDate()
      if (data.start) date_picker_date.start = data.start
      if (data.end) date_picker_date.end = data.end
      if (data.shortcut) date_picker_date.shortcut = data.shortcut
    }

    return date_picker_date
  }
}
