import store from '@/store'
import { Md5 } from 'ts-md5/dist/md5'
import { getModule } from 'vuex-module-decorators'
import CloseoutModule from '@/store/model/CloseoutModule'
import Company from './Company'
import Api from './Api'
import Invoice from './Invoice'
import WebMessage from './WebMessage'
import PaginateOptions from './interface/PaginateOptions'

export default class Closeout {
    public id: string | null = null;

    public year: number = 2019;

    public month: number = 1;

    public impressions: number = 0;

    public impressions_publisher: number = 0;

    public impressions_advertiser: number = 0;

    public rate_publisher: number = 0;

    public rate_advertiser: number = 0;

    public partner_id: string = '';

    public company_id: string = '';

    public user_id: string = '';

    public publisher: string = '';

    public selected: boolean = false;

    public cost: number = 0;

    public revenues: number = 0;

    public net: number = 0;

    public invoice_id: string | null = null;

    public custom: boolean = false;

    public _invoice: Invoice | null = null;

    public get invoice(): Invoice | null {
      if (!this._invoice && this.invoice_id) this._invoice = Invoice.find(this.invoice_id)

      return this._invoice
    }

    public get dynamicCost(): number {
      return this.rate_publisher * (this.impressions_publisher / 1000)
    }

    public get dynamicRevenues(): number {
      return this.rate_advertiser * (this.impressions_advertiser / 1000)
    }

    public get dynamicNet(): number {
      return this.dynamicRevenues - this.dynamicCost
    }

    public get advertiser_discrepancy(): number {
      if (this.impressions == 0) return 0
      return (this.impressions_advertiser / this.impressions) - 1
    }

    public get publisher_discrepancy(): number {
      if (this.impressions == 0) return 0
      return (this.impressions_publisher / this.impressions) - 1
    }

    private _company: Company | null = null;

    get company(): Company | null {
      if (this.company_id && (this._company == null || this._company.id != this.company_id)) {
        this._company = Company.find(this.company_id)
      }

      return this._company
    }

    private _partner: Company | null = null;

    get partner(): Company | null {
      if (this.partner_id && (this._partner == null || this._partner.id != this.partner_id)) {
        this._partner = Company.find(this.partner_id)
      }

      return this._partner
    }

    public get partner_name(): Company | null | string {
      if (this.partner instanceof Company) {
        return this.partner.name
      }
      return this.partner
    }

    public _pub: Company | null = null;

    public get pub(): Company | null {
      if (!this._pub) {
        const o = Company.module.data.find((o: Company) => o.name.includes(this.publisher.substring(0, 3)))
        if (o instanceof Company) this._pub = o
      }
      return this._pub
    }

    public _key !: string | Int32Array;

    public get key() {
      if (!this._key) {
        this._key = Md5.hashStr(this.publisher + this.year + this.partner_id + this.month)
      }
      return this._key
    }

    public get date() {
      if (this.month < 10) return `${this.year}-0${this.month}-01`
      return `${this.year}-${this.month}-01`
    }

    public save() {
      const api = new Api()

      if (this.id) {
        return api.put(`closeout/${this.id}`, { closeouts: [this] })
      }
      return api.post('closeout', this)
    }

    public delete() {
      const api = new Api()

      return api.delete(`closeout/${this.id}`, {}).then(this.onDelete)
    }

    private onDelete(response: any) {
      const closeouts = Closeout.filter(response.data.result.deleted)

      const pending = Closeout.toObjectList(response.data.result.pending)

      WebMessage.success('Closeout deleted!')

      Closeout.module.delete(closeouts)

      return response
    }

    public static toObject(data: any, cache: boolean = true): Closeout {
      const closeout = new Closeout()

      closeout.id = data.id
      closeout.year = data.year
      closeout.month = data.month
      closeout.impressions = data.impressions
      closeout.impressions_publisher = data.impressions_publisher
      closeout.impressions_advertiser = data.impressions_advertiser
      closeout.rate_publisher = data.rate_publisher
      closeout.rate_advertiser = data.rate_advertiser
      closeout.partner_id = data.partner_id
      closeout.company_id = data.company_id
      closeout.user_id = data.user_id
      closeout.publisher = data.publisher
      closeout.selected = data.selected
      closeout.cost = data.cost
      closeout.revenues = data.revenues
      closeout.net = data.net
      closeout.invoice_id = data.invoice_id
      closeout.custom = data.custom

      if (!closeout.id) {
        if (closeout.partner instanceof Company) closeout.rate_advertiser = closeout.partner.rate

        if (closeout.pub instanceof Company) closeout.rate_publisher = closeout.pub.rate
      }

      if (data.partner) {
        Company.toObject(data.partner)
      }

      if (data.company) {
        Company.toObject(data.company)
      }

      //  Cache Object
      if (cache) Closeout.module.update(closeout)

      return closeout
    }

    public static toObjectList(data: any, cache: boolean = true): Closeout[] {
      const closeouts = new Array<Closeout>()
      data.forEach((value: any) => {
        const closeout = Closeout.toObject(value, false)
        closeouts.push(closeout)
      })

      //  Cache Object
      if (cache) Closeout.module.update(closeouts)

      return closeouts
    }

    // State Management
    public static get module(): CloseoutModule {
      if (!store.hasModule('closeout')) {
        store.registerModule('closeout', CloseoutModule)
      }

      return getModule(CloseoutModule)
    }

    public static find(id: string): Closeout | null {
      const o = Closeout.module.data.find(closeout => closeout.id === id)
      return o instanceof Closeout ? o : null
    }

    public static filter(filter: any): Closeout[] {
      if (Array.isArray(filter)) {
        return Closeout.module.data.filter(closeout => closeout.id && filter.includes(closeout.id))
      }
      return Closeout.module.data.filter(filter)
    }

    public static async get(id: string): Promise<Closeout | null> {
      return Closeout.module.find(id)
    }

    public static async getOpen(): Promise<Closeout[]> {
      return Closeout.module.getOpen()
    }

    public static async getPending(): Promise<Closeout[]> {
      return Closeout.module.getPending()
    }

    public static async paginate(options: PaginateOptions) {
      return Closeout.module.paginate(options)
    }
}
