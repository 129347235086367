
































































































































import { Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Closeout from '@/models/Closeout'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import InvoiceBadgeStatus from '../Invoice/Components/InvoiceBadgeStatus.vue'

@Component({
  components: {
    Widget,
    InvoiceBadgeStatus,
    IconAction,
    SearchInput,
  },
})
export default class CloseoutHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public closeout: Closeout = new Closeout()

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public query: string[] = []

  public modal: any = {
    delete: false,
  }

  public fields: any = [
    {
      key: 'date',
      label: 'Date',
      class: 'text-center',
      show: true,
    },
    {
      key: 'publisher',
      label: 'Publisher',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'partner_name',
      label: 'Partner',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'impressions',
      label: 'Impressions',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'impressions_publisher',
      label: 'Publisher Impressions',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'rate_publisher',
      label: 'Rate Publisher',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'impressions_advertiser',
      label: 'Advertiser Impressions',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'rate_advertiser',
      label: 'Rate Advertiser',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'net',
      label: 'Net',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'created_at',
      label: 'Created At',
      sortable: true,
      class: 'text-center',
      show: false,
    },
    {
      key: 'updated_at',
      label: 'Update At',
      sortable: true,
      class: 'text-center',
      show: false,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'action',
      label: 'Action',
      sortable: false,
      show: true,
    },
  ]

  public query_settings: any = {
    company_fields: [
      {
        name: 'partner',
        key: 'partner_id',
      },
    ],
    custom_fields: [
      {
        name: 'status:pending',
        value: 'is:pending',
      },
      {
        name: 'status:draft',
        value: 'is:draft',
      },
      {
        name: 'status:paid',
        value: 'is:paid',
      },
      {
        name: 'status:overdue',
        value: 'is:overdue',
      },
      {
        name: 'status:sent',
        value: 'is:sent',
      },
      {
        name: 'status:partially_paid',
        value: 'is:partially_paid',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public closeouts(context: any) {
    this.loading = true
    return Closeout.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: context.filter,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public confirmDelete(closeout: Closeout) {
    this.closeout = closeout
    this.modal.delete = true
  }

  public deleteCloseout() {
    this.closeout.delete().then(() => {
      this.dataTable.refresh()
    })
  }

  public createCloseout() {
    this.$router.push('/app/closeout')
  }

  public editCloseout(id: string) {
    this.$router.push(`/app/closeout/${id}`)
  }

  public created() {
    this.records = this.closeouts.length
  }
}
